import request from '@/utils/request';
const USER_PATH = '/user';

const listBoard = (query) => {
  return request({
    url: `${USER_PATH}/board/board/`,
    method: 'get',
    params: query
  });
};

const getBoard = (noticeId) => {
  return request({
    url: `${USER_PATH}/board/board/${noticeId}/`,
    method: 'get'
  });
};

const addBoard = (params) => {
  return request({
    url: `${USER_PATH}/board/board/`,
    method: 'post',
    params
  });
};

const updateBoard = (params) => {
  return request({
    url: `${USER_PATH}/board/board/`,
    method: 'post',
    params
  });
};

const delBoard = (noticeId) => {
  return request({
    url: `${USER_PATH}/board/board/${noticeId}/`,
    method: 'delete'
  });
};

export { listBoard, getBoard, addBoard, updateBoard, delBoard };
