<template>
  <ion-list>
    <ion-item-sliding>
      <template v-if="total > 0">
        <ion-item v-for="(item, index) in list.datas" :key="index" @click="detail(item)">
          <ion-avatar slot="start">
            <img :src="item.profileImage">
          </ion-avatar>
          <ion-label>
            <h2>{{ item.writer }}</h2>
            <h3>{{ item.title }}</h3>
            <p v-html="item.contents"></p>
          </ion-label>
        </ion-item>
      </template>
      <template v-else>
        <ion-item>
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h2><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></h2>
            <h3><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></h3>
            <p><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
          </ion-label>
        </ion-item>
      </template>
    </ion-item-sliding>
    
    <ion-modal
      :is-open="isOpenRef"
      css-class="my-custom-class"
      @didDismiss="setOpen(false)"
    >
      <Modal :title="detailTitle" :modal-data="detailData" @close="setClose"></Modal>
    </ion-modal>
  </ion-list>

  <ion-infinite-scroll
    @ionInfinite="loadData($event)" 
    threshold="100px" 
    id="infinite-scroll"
    :disabled="!nextLoading"
  >
    <ion-infinite-scroll-content
      loading-spinner="bubbles"
      loading-text="로딩중...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</template>

<script>
import { 
  IonList,
  IonItemSliding,
  IonItem,
  IonAvatar,
  IonLabel,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue';
import { defineComponent, ref, reactive, watch } from 'vue';
import Modal from './ListDetailModal'

export default defineComponent({
  name: 'List',
  components: { 
    IonList,
    IonItemSliding,
    IonItem,
    IonAvatar,
    IonLabel,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    Modal 
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    datas: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    pageNum: {
      type: Number,
      default: 0
    },
    nextLoading: {
      type: Boolean,
      default: true
    },
  },
  setup(props, { emit }) {
    const isOpenRef = ref(false)
    const setOpen = (state) => isOpenRef.value = state

    let list = reactive({
      datas: []
    })
    let detailTitle = reactive(undefined)
    let detailData = reactive({})

    const detail = (item) => {
      detailTitle = item.title

      detailData.id = item.id
      detailData.writer = item.writer
      detailData.title = item.title
      detailData.contents = item.contents
      detailData.profileImage = item.profileImage
      detailData.profile = item.profile
      
      setOpen(true)
    }
  
    watch(() => props.datas, (newVal) => {
      newVal.map(o => o.profileImage = require('./avatar-rey.png'))
      list.datas = newVal
    },
    { 
      deep: true 
    })

    const setClose = (state) => {
      isOpenRef.value = state
    }

    const pushData = () => {
      if (props.nextLoading) {
        emit('nextPage', Number(props.pageNum) + 1)
      }

      /* const max = list.datas.length + 20;
      const min = max - 20;
      for (let i = min; i < max; i++) {
        list.datas.push({
          id: '',
          writer: '',
          title: `테스트${i}`,
          contents: `테스트 내용${i}`,
          profileImage: '',
          profile: ''
        });
      } */
    }

    const loadData = (ev) => {
      setTimeout(() => {
        if (props.nextLoading) {
          pushData();
        }
        ev.target.complete();
      }, 500);
    }

    pushData()

    return { 
      isOpenRef, 
      setOpen, 
      list,
      detail,
      detailTitle,
      detailData,
      setClose,
      loadData
    }
  }
});
</script>
