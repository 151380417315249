<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="setClose">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>
          <ion-chip>
            <ion-avatar>
              <ion-img :src="modalData.profileImage"></ion-img>
            </ion-avatar>
            <ion-label>{{ modalData.writer}}</ion-label>
          </ion-chip>
        </ion-card-subtitle>
        <ion-card-title>{{ modalData.title }}</ion-card-title>
      </ion-card-header>
      <ion-card-content v-html="modalData.contents"></ion-card-content>
    </ion-card>
  </ion-content>  
</template>

<script>
import { 
  IonHeader, 
  IonToolbar, 
  IonTitle,
  IonButtons, 
  IonButton, 
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonAvatar,
  IonImg,
  IonLabel,
  IonCardContent 
} from '@ionic/vue';
import { defineComponent } from 'vue'

export default defineComponent({
  components: { 
    IonHeader, 
    IonToolbar, 
    IonTitle,
    IonButtons, 
    IonButton, 
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonChip,
    IonAvatar,
    IonImg,
    IonLabel,
    IonCardContent
  },
  props: {
    title: {
      type: String,
      default: '상세'
    },
    modalData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const setClose = () => {
      emit('close', false)
    }

    return {
      setClose
    }
  }
})
</script>