<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <List name="Board" :datas="boardList" :total="total" :page-num="currentPageNum" :next-loading="disabled" @next-page="nextPage" />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import List from '@/components/List';
import { listBoard } from "@/api/board/board";

export default defineComponent({
  name: 'Tab1',
  components: { 
    List, 
    IonContent, 
    IonPage
  },
  setup() {
    let total = ref(0)
    let currentPageNum = ref(0)
    let boardList = ref([])
    let nextLoading = ref(true);

    /* onMounted(async () => {
      await getList()
    }) */

    const getList = async () => {
      if (nextLoading.value) {
        const query = {
          pageNum: currentPageNum.value
        }
        const res = await listBoard(query)

        total.value = res.count
        if (currentPageNum.value === 1) {
          boardList.value = res.results
        } else if (res.results) {
          boardList.value.push(...res.results)
        } else if (!res.next) {
          nextLoading.value = false
        }
      } 
    }

    const nextPage = async (pageNum) => {
      currentPageNum.value = pageNum
      await getList()
    }

    return {
      boardList,
      total,
      currentPageNum,
      nextPage
    }
  }
});
</script>
